import React from "react"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import "./Share.scss"
import "../../node_modules/@fortawesome/fontawesome-free/css/all.css"
import "../templates/global.scss"

const Share = ({ socialConfig, tags }) => (
  <div className="social-share">
    <FacebookShareButton
      url={socialConfig.config.url}
      quote={socialConfig.config.title}
      className="facebook"
    >
      <span className="icon is-large is-facebook">
        <i className="fab fa-lg fa-facebook-f"></i>
      </span>
    </FacebookShareButton>
    <TwitterShareButton
      url={socialConfig.config.url}
      className="twitter"
      title={socialConfig.config.title}
      via={socialConfig.twitterHandle.split("@").join("")}
      hashtags={tags}
    >
      <span className="icon is-large is-twitter">
        <i className="fab fa-lg fa-twitter"></i>
      </span>
    </TwitterShareButton>
    <LinkedinShareButton
      url={socialConfig.config.url}
      className="linkedin"
      title={socialConfig.config.title}
    >
      <span className="icon is-large is-linkedin">
        <i className="fab fa-lg fa-linkedin-in"></i>
      </span>
    </LinkedinShareButton>
  </div>
)

Share.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
Share.defaultProps = {
  tags: [],
}

export default Share
